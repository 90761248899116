import { addChannel } from '@/apis/api';
import ChannelFormModalEditor from './ChannelFormModalEditor';

export default function AddChannel({ onSuccess }: { onSuccess: () => void }) {
  return (
    <ChannelFormModalEditor
      onSuccess={onSuccess}
      submit={function (data: I.Channel): Promise<unknown> {
        return addChannel(data);
      }}
      title="添加渠道"
      buttonText="添加"
    />
  );
}
