import {
  LoginForm,
  ProFormCaptcha,
  ProFormText,
} from '@ant-design/pro-components';
import { message } from 'antd';
import { MobileOutlined, LockOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { getCode, login } from '@/apis/api';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const { loading, runAsync } = useRequest(login, {
    manual: true,
  });

  const navigate = useNavigate();

  const [token, setToken] = useState<number>();

  const onFinish = async (formData: {
    mobile: string;
    password: string;
    code: string;
  }) => {
    try {
      await runAsync({ ...formData, token });
    } catch (e: any) {
      message.error(e.message || '未知错误');
      return false;
    }
    message.success('登录成功', 1);
    navigate('/');
    return true;
  };
  return (
    <div>
      <LoginForm title="RMS" onFinish={onFinish} loading={loading}>
        <ProFormText
          name="mobile"
          placeholder={'请输入手机号'}
          fieldProps={{
            size: 'large',
            prefix: <MobileOutlined className={'prefixIcon'} />,
          }}
          rules={[
            {
              required: true,
              message: '请输入手机号！',
            },
            {
              pattern: /^1\d{10}$/,
              message: '手机号格式错误！',
            },
          ]}
        />
        <ProFormText.Password
          name="password"
          fieldProps={{
            size: 'large',
            prefix: <LockOutlined className={'prefixIcon'} />,
          }}
          placeholder={'密码'}
          rules={[
            {
              required: true,
              message: '请输入密码！',
            },
          ]}
        />
        <ProFormCaptcha
          fieldProps={{
            size: 'large',
            prefix: <LockOutlined className={'prefixIcon'} />,
          }}
          captchaProps={{
            size: 'large',
          }}
          placeholder={'请输入验证码'}
          captchaTextRender={(timing, count) => {
            if (timing) {
              return `${count} ${'获取验证码'}`;
            }
            return '获取验证码';
          }}
          name="code"
          rules={[
            {
              required: true,
              message: '请输入验证码！',
            },
          ]}
          onGetCaptcha={async () => {
            return getCode()
              .then((n) => {
                setToken(n);
              })
              .then(() => {
                message.success('获取验证码成功！');
              });
          }}
        />
      </LoginForm>
    </div>
  );
}
