import React, { Suspense } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './routes';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';

function App() {
  return (
    <React.StrictMode>
      <ConfigProvider locale={zhCN}>
        <Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={router} />
        </Suspense>
      </ConfigProvider>
    </React.StrictMode>
  );
}

export default App;
