import { updateChannel } from '@/apis/api';
import ChannelFormModalEditor from './ChannelFormModalEditor';

export default function UpdateChannel({
  onSuccess,
  channel,
}: {
  onSuccess: () => void;
  channel: I.Channel;
}) {
  return (
    <ChannelFormModalEditor
      onSuccess={onSuccess}
      initData={channel}
      submit={function (data: I.Channel): Promise<unknown> {
        return updateChannel(channel.id, data);
      }}
      title="修改渠道"
      buttonText="编辑"
      disabledField={['name', 'company_id', 'source_id']}
    />
  );
}
