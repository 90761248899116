import {
  listCustomer,
  listAllCompany,
  listCompanyInfo,
  listCustomerInfo,
  cleanCustomer,
} from '@/apis/api';
import { PageHeader } from '@ant-design/pro-components';
import { useAntdTable, useRequest } from 'ahooks';
import { Params } from 'ahooks/lib/useAntdTable/types';
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Popover,
  Rate,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  message,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import { formatSearchFilterAllValue, formatTime } from '@/utils';
import { createContext, useContext, useMemo } from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import { SourceSelect } from '@/components/SourceSelect';
import DatetimePicker from '@/components/DatetimePicker';
import { customerStateMapping } from '@/constants';
import useUser from '@/hooks/user';
import ChannelSelect from '@/components/ChannelSelect';

const companyContext = createContext<{
  company?: I.Company[];
  loading?: boolean;
  companyInfo?: (I.CompanyInfo & { company?: I.Company })[];
}>({});

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4093617_k95i7isd6z.js',
});

function ExtraInfo({ customer: { remark, extra } }: { customer: I.Customer }) {
  return (
    <Space direction="vertical">
      <span>{remark}</span>
      <span>
        <Space>
          {extra?.has_house && (
            <Tooltip title="房">
              <IconFont type="icon-shouye" />
            </Tooltip>
          )}
          {extra?.has_car && (
            <Tooltip title="车">
              <IconFont type="icon-car-v2-full" />
            </Tooltip>
          )}
          {extra?.has_credit_card && (
            <Tooltip title="信用卡">
              <IconFont type="icon-xinyongqia" />
            </Tooltip>
          )}
          {extra?.has_insurance && (
            <Tooltip title="寿险保险">
              <IconFont type="icon-weibiaoti2fuzhi10" />
            </Tooltip>
          )}
          {extra?.has_social_security && (
            <Tooltip title="社保">
              <IconFont type="icon-shebao" />
            </Tooltip>
          )}
          {extra?.has_wages && (
            <Tooltip title="代发工资">
              <IconFont type="icon-gongzitiao" />
            </Tooltip>
          )}
          {extra?.has_provident_fund && (
            <Tooltip title="公积金">
              <IconFont type="icon-gongjijin" />
            </Tooltip>
          )}
          {extra?.has_business_license && (
            <Tooltip title="营业执照">
              <IconFont type="icon-yingyezhizhao" />
            </Tooltip>
          )}
        </Space>
      </span>
    </Space>
  );
}

function PushTarget({
  companyId,
  targetId,
}: {
  companyId: I.ID;
  targetId?: I.ID;
}) {
  const { loading, company, companyInfo } = useContext(companyContext);

  const user = useMemo(() => {
    // 先找到公司
    const c = company?.find((item) => item.id === companyId);
    const ci = companyInfo?.find((item) => item.company?.id === companyId);
    if (!c || !ci) {
      return undefined;
    }
    // 再找到公司下的用户
    const user = ci.users?.find((item) => item.id === targetId);
    // 再找到部门
    const department = ci.departments?.find(
      (item) => item.id === user?.departmentId,
    );

    return { user, department, company: c };
  }, [company, companyInfo, companyId, targetId]);

  return (
    <Spin spinning={loading}>
      <Space>
        <span>{user?.company.name}</span>
        <span>{user?.department?.name}</span>
        <span>{user?.user?.name}</span>
      </Space>
    </Spin>
  );
}

function Push({ company_id, push }: { push?: I.Push; company_id?: I.ID }) {
  const status = useMemo(() => {
    switch (push?.status) {
      case 0:
        return (
          <Tooltip title="等待推送">
            <IconFont type="icon-paidui" />
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip title="推送成功">
            <IconFont type="icon-push" />
          </Tooltip>
        );
      case 2:
        return (
          <Popover title="推送失败" content={<pre>{push?.log}</pre>}>
            <IconFont type="icon-failed" />
          </Popover>
        );
      default:
        return (
          <Tooltip title="等待推送">
            <IconFont type="icon-paidui" />
          </Tooltip>
        );
    }
  }, [push?.status, push?.log]);

  if (!company_id) {
    return null;
  }
  return (
    <Space>
      <PushTarget companyId={company_id} targetId={push?.target} />
      <span>{status}</span>
    </Space>
  );
}

const CustomerFrom = ({
  source,
  channel,
}: {
  source: I.Source;
  channel?: I.Channel;
}) => {
  const { user } = useUser();
  return (
    <Space>
      {user.role !== 3 && <span>{source.name}</span>}
      <span>{channel?.name}</span>
    </Space>
  );
};

const CustomerInfo = ({ value }: { value: I.Customer }) => {
  const { user } = useUser();
  return (
    <Space direction="vertical">
      <span>{value.name}</span>
      <Tooltip title={user.role !== 3 && value.mobile}>
        {value.mobile?.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}
      </Tooltip>
    </Space>
  );
};

const columns: (ColumnType<I.Customer & { info?: I.CustomerInfo }> & {
  hideInAgent?: boolean;
})[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: '客户信息',
    render: (value: I.Customer) => {
      return <CustomerInfo value={value} />;
    },
  },
  {
    title: 'CRM',
    width: 130,
    render: (_, value) => {
      return (
        <Space direction="vertical">
          <Rate style={{ fontSize: '12px' }} disabled value={value.star || 0} />
          {value.state ? (
            <span>{customerStateMapping[value.state]}</span>
          ) : (
            <span>待跟进</span>
          )}
        </Space>
      );
    },
  },
  {
    title: '跟进信息',
    dataIndex: 'info',
    render: (info?: I.CustomerInfo) => {
      return <span>{info?.lastEvent?.comment}</span>;
    },
  },
  {
    title: '客户来源',
    render: (_: unknown, { source, channel }) => {
      return <CustomerFrom source={source} channel={channel} />;
    },
  },
  {
    title: '额外信息',
    hideInAgent: true,
    render: (_: unknown, customer) => <ExtraInfo customer={customer} />,
  },
  {
    title: '推送信息',
    hideInAgent: true,
    render: (_: unknown, { push }) => {
      return <Push company_id={push?.company_id} push={push} />;
    },
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return formatTime(created_at);
    },
  },
  {
    title: '推送时间',
    render: (_: unknown, { push }) => {
      if (push?.updated_at) {
        return formatTime(push?.updated_at);
      }
      return '-';
    },
  },
];

const ClearButton = ({ onSuccess }: { onSuccess: () => void }) => {
  const { loading, run } = useRequest(cleanCustomer, {
    manual: true,
    onError: (e) => {
      message.error(e.message);
    },
    onSuccess: () => {
      message.success('清空成功');
      onSuccess();
    },
  });
  return (
    <Popconfirm
      title="确定清空所有客户信息吗？"
      onConfirm={run}
      okText="确定"
      cancelText="取消"
    >
      <Button loading={loading} danger type="primary">
        清空
      </Button>
    </Popconfirm>
  );
};

export default function CustomerIndex() {
  const [form] = Form.useForm();

  const { user } = useUser();

  const { data: company, loading: companyLoading } = useRequest(() => {
    return listAllCompany();
  });

  const { data: companyInfo, loading: companyInfoLoading } = useRequest(
    () => {
      const target = company?.filter((item) => item.api && item.token);
      return Promise.all(
        target?.map((item) => listCompanyInfo(item.id)) || [],
      ).then((res) => {
        return res.map((item, idx) => {
          return {
            company: target?.[idx],
            ...item,
          };
        });
      });
    },
    {
      refreshDeps: [company],
      ready: !!company,
    },
  );

  const status = Form.useWatch('push_status', form);

  const {
    tableProps,
    loading: tableLoading,
    search,
  } = useAntdTable<
    Awaited<ReturnType<typeof listCustomer>>,
    [Params[0], Params[1]]
  >(
    (page, formData) => {
      return listCustomer({
        page: page.current,
        size: page.pageSize,
        ...formData,
        created_at: undefined,
        push_status: formatSearchFilterAllValue(formData.push_status),
        source_id: formatSearchFilterAllValue(formData.source_id),
        channel_id: formatSearchFilterAllValue(formData.channel_id),
        started_at: formData?.created_at?.startedAt,
        ended_at: formData?.created_at?.endedAt,
      }).then((res) => {
        if (res.list?.length) {
          return listCustomerInfo(res.list.map((item) => item.id)).then(
            (result) => {
              return {
                ...res,
                list: res.list.map((item) => {
                  return {
                    ...item,
                    info: result[item.id],
                  };
                }),
              };
            },
          );
        }
        return res;
      });
    },
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, {}],
      ready: !!company,
    },
  );

  const loading = tableLoading || companyInfoLoading || companyLoading;

  const { submit, reset } = search;

  const userColumns = useMemo(() => {
    return columns.filter((item) => {
      if (user.role === 3) {
        return !item.hideInAgent;
      }
      return true;
    });
  }, [user.role]);

  return (
    <companyContext.Provider value={{ company, loading, companyInfo }}>
      <PageHeader
        title={'资源管理'}
        extra={
          status === '0' &&
          user.role === 9 && <ClearButton onSuccess={() => reset()} />
        }
      >
        <Form
          form={form}
          initialValues={{ source_id: '', channel_id: '', push_status: '' }}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label="客户姓名" name="name">
                <Input placeholder="客户姓名" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="电话信息" name="mobile">
                <Input placeholder="电话信息" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="推送状态" name="push_status">
                <Select>
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value="0">等待推送</Select.Option>
                  <Select.Option value="1">推送成功</Select.Option>
                  <Select.Option value="2">推送失败</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {user.role !== 3 && (
              <Col span={6}>
                <Form.Item label="所属媒体" name="source_id">
                  <SourceSelect showAll allowClear />
                </Form.Item>
              </Col>
            )}
            <Col span={6}>
              <Form.Item label="所属渠道" name="channel_id">
                <ChannelSelect allowClear />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="时间范围" name="created_at">
                <DatetimePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="end" style={{ marginBottom: 24 }}>
            <Space>
              <Button type="primary" onClick={submit}>
                搜索
              </Button>
              <Button onClick={reset}>重置</Button>
            </Space>
          </Row>
        </Form>
        <Table
          columns={userColumns}
          {...tableProps}
          loading={loading}
          rowKey={'id'}
        />
      </PageHeader>
    </companyContext.Provider>
  );
}
