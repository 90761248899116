import { listAllCompany } from '@/apis/api';
import { useRequest } from 'ahooks';
import { Select, SelectProps } from 'antd';

export function CompanySelect({
  showAll,
  ...props
}: SelectProps<I.ID> & { showAll?: boolean }) {
  const { data, loading } = useRequest(() => {
    return listAllCompany();
  });
  return (
    <Select {...props} loading={loading}>
      {showAll && <Select.Option value={''}>全部</Select.Option>}
      {data?.map((company) => (
        <Select.Option key={company.id} value={company.id}>
          {company.name}
        </Select.Option>
      ))}
    </Select>
  );
}
