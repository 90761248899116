import useUser from '@/hooks/user';
import { Avatar, Col, DropDownProps, Dropdown, Row, Spin, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { logout } from '@/apis/api';

export function UserInfoMenu({ ...props }: DropDownProps) {
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { loading: logoutLoading, run: doLogout } = useRequest(
    () => {
      return logout();
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('退出登录成功', 1);
        navigate('/login');
      },
    },
  );
  return (
    <Dropdown
      {...props}
      trigger={['hover']}
      menu={{
        style: { width: 110 },
        disabled: logoutLoading,
        items: [
          {
            label: '修改密码',
            key: 'updatePassword',
            onClick: () => {
              navigate('/user/updatePassword');
            },
          },
          {
            label: '退出登录',
            key: 'logout',
            onClick: () => {
              doLogout();
            },
          },
        ],
      }}
    >
      <Spin spinning={loading}>
        <Row
          gutter={10}
          style={{
            cursor: 'pointer',
            marginTop: -2,
            userSelect: 'none',
            padding: '0 10px',
          }}
        >
          <Col>{user && <Avatar size="default" icon={<UserOutlined />} />}</Col>
          <Col>{user?.name}</Col>
        </Row>
      </Spin>
    </Dropdown>
  );
}
