import { Layout, Menu, MenuProps, theme } from 'antd';
import { Suspense, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LayoutHeader from './LayoutHeader';
import WatermarkWrapper from './WatermarkWrapper';
import useUser, { UserProvider } from '@/hooks/user';
import { CompanyProvider } from '@/hooks/company';

const { Sider, Content, Footer } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const menus: (MenuItem & { agentShow?: boolean })[] = [
  {
    key: '/',
    label: '首页',
  },
  {
    key: '/source',
    label: '媒体管理',
  },
  {
    key: '/channel',
    label: '渠道管理',
    agentShow: true,
  },
  {
    key: '/channel_push',
    label: '推送管理',
  },
  {
    key: '/customer',
    label: '资源管理',
  },
  {
    key: '/company',
    label: '公司管理',
  },
  {
    key: '/report',
    label: '报表中心',
    children: [
      { key: '/report/channel', label: '渠道统计' },
      // {
      //   key: '/report/new_data',
      //   label: '数据跟进',
      // },
      // {
      //   key: '/report/handle',
      //   label: '工作跟进',
      // },
    ],
  },
  // {
  //   key: '/user',
  //   label: '用户管理',
  // },
];

const UserMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, loading } = useUser();

  const userMenus = useMemo(() => {
    if (loading) {
      return [];
    }
    if (user.role === 3) {
      return menus.filter((menu) => menu.agentShow);
    }
    return menus;
  }, [user, loading]);

  return (
    <Menu
      onClick={({ key }) => {
        navigate(key);
      }}
      selectedKeys={[location.pathname]}
      theme="dark"
      mode="inline"
      items={userMenus}
    />
  );
};

export default function AdminLayout() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <UserProvider>
      <CompanyProvider>
        <Layout hasSider>
          <Sider
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <span
              style={{
                color: '#fff',
                fontSize: '24px',
                fontWeight: 'bold',
                height: '32px',
                margin: '16px',
                background: 'rgba(255,255,255,.2)',
                borderRadius: '6px',
                textAlign: 'center',
                lineHeight: '32px',
                display: 'block',
              }}
            >
              RMS
            </span>
            <UserMenu />
          </Sider>
          <Layout style={{ marginLeft: 200 }}>
            <LayoutHeader />
            <Content
              style={{
                margin: '24px 16px 0',
                height: 'calc(100vh - 64px - 64.5px - 32px)',
                overflowY: 'auto',
              }}
            >
              <div style={{ padding: 24, background: colorBgContainer }}>
                <WatermarkWrapper>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Outlet />
                  </Suspense>
                </WatermarkWrapper>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>RMS</Footer>
          </Layout>
        </Layout>
      </CompanyProvider>
    </UserProvider>
  );
}
