import { listAllChannel } from '@/apis/api';
import { useRequest } from 'ahooks';
import { Select, SelectProps } from 'antd';

export default function ChannelSelect({
  sourceId,
  ...props
}: SelectProps & { sourceId?: I.ID }) {
  const { data, loading } = useRequest(
    () => {
      return listAllChannel().then((result) => {
        if (sourceId) {
          return result.filter((c) => c.source_id === sourceId);
        }
        return result;
      });
    },
    {
      refreshDeps: [sourceId],
    },
  );

  return (
    <Select
      loading={loading}
      showSearch
      filterOption={(input, option) => {
        return !!option?.label
          ?.toLocaleString()
          .toLocaleLowerCase()
          .includes(input.toLocaleLowerCase());
      }}
      options={data
        ?.filter(({ hide }) => !hide)
        ?.map((channel) => ({
          label: `${channel.name} - ${channel.edges.source.name}`,
          value: channel.id,
        }))}
      {...props}
    />
  );
}
