import { Tooltip } from 'antd';
import { CheckCircleTwoTone, StopTwoTone } from '@ant-design/icons';

export default function BoolStatus({ status }: { status?: boolean }) {
  return status ? (
    <Tooltip title={'启用'}>
      <CheckCircleTwoTone twoToneColor="#52c41a" />
    </Tooltip>
  ) : (
    <Tooltip title={'禁用'}>
      <StopTwoTone twoToneColor={'red'} />
    </Tooltip>
  );
}
