import useUser from '@/hooks/user';
import { PropsWithChildren } from 'react';

export default function RouteAuth({
  children,
  agentShow,
}: PropsWithChildren & { agentShow?: boolean }) {
  const { user } = useUser();
  if (user.role === 3 && !agentShow) {
    return null;
  }
  return <>{children}</>;
}
