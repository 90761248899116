import { RequestFailed } from '@/apis/RequestFailed';
import { getCurrentUser } from '@/apis/api';
import { useRequest } from 'ahooks';
import { Spin, message } from 'antd';
import { PropsWithChildren, createContext, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const context = createContext<{
  loading?: boolean;
  user: I.User;
  error?: Error;
}>({
  user: {} as I.User,
});

export const UserProvider = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation();

  const {
    data: user,
    loading,
    error,
  } = useRequest(
    () => {
      return getCurrentUser();
    },
    { refreshDeps: [location] },
  );

  if (error) {
    if (error instanceof RequestFailed && error.statusCode === 401) {
      message.warning('登录失效，请先登录');
    } else {
      message.error(error?.message || '未知错误');
    }

    return <Navigate to="/login" state={location} replace />;
  }

  return (
    <Spin spinning={loading}>
      {user && (
        <context.Provider value={{ user, loading, error }}>
          {children}
        </context.Provider>
      )}
    </Spin>
  );
};

export default function useUser() {
  return useContext(context);
}
