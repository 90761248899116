import { createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';
import { generateRoutes } from './router.helper';
import routes from './config';
import AdminLayout from '@/components/Layout';
import Login from '@/pages/Login';
import TestPush from '@/pages/TestPush';

const NotFound = lazy(() => import('@/pages/404'));

const router = createBrowserRouter([
  {
    element: <AdminLayout />,
    errorElement: <div>error layout</div>,
    children: generateRoutes(routes),
  },
  {
    element: <Login />,
    path: '/login',
  },
  {
    path: '/push/:source_id',
    element: <TestPush />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
