import { Col, Layout, Row, Space, theme } from 'antd';
import { UserInfoMenu } from './UserInfoMenu';

const { Header } = Layout;

export default function LayoutHeader() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
        height: '64px',
        lineHeight: '64px',
      }}
    >
      <Row justify="end" align="middle">
        <Col>
          <Space>
            <UserInfoMenu />
          </Space>
        </Col>
      </Row>
    </Header>
  );
}
