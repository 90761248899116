import type { RouteObject } from 'react-router-dom';
import RouteAuth from './RouteAuth';
// import type { IconType } from '@/components/Icons';
import type { MergeExclusive, SetOptional } from 'type-fest';

interface BaseMenuItem {
  // 菜单key 同时也是菜单path
  key: string;
  // 菜单名称 同时也是面包屑名称
  name: string;
  // icon?: IconType;
  auth?: true;
  path: string;
  // 页面组件地址，基于pages文件夹下
  component: React.ReactNode;
}

interface NoStateMenuItem extends BaseMenuItem {
  menuRender: false;
  // 当打开一个非菜单页面（也就是页面的menuRender为false）想让菜单的某一项高亮，那么把此属性设为高亮菜单页面的key。
  parentKey?: string;
}

type MenuItemRoute = MergeExclusive<BaseMenuItem, NoStateMenuItem>;

type MenuFoldRoute = SetOptional<
  Omit<MenuItemRoute, 'componentPath' | 'layoutRender' | 'parentKey' | 'auth'>,
  'path'
> & {
  children: Array<MergeExclusive<MenuItemRoute, MenuFoldRoute>>;
};

export type MenuRoute = MergeExclusive<MenuItemRoute, MenuFoldRoute>;

type CustomRoute = Omit<BaseMenuItem, 'key' | 'name' | 'icon'> & {
  layoutRender?: false;
};

export type IRoute = MergeExclusive<CustomRoute, MenuRoute>;

/**
 * 从路由配置中获取具有Layout的routes
 * @param {Array<IRoute>} routes
 * @returns
 */
export const getLayoutRoutes = function (routes: IRoute[]) {
  const layoutRoutes: IRoute[] = [];
  routes.forEach((route) => {
    const _route = { ...route };
    if (_route.layoutRender !== false) {
      if (_route.children) {
        _route.children = getLayoutRoutes(_route.children) as MenuRoute[];
      }
      layoutRoutes.push(_route);
    }
  });
  return layoutRoutes;
};

/**
 * 从路由配置中获取不需要Layout的routes
 * @param {Array<IRoute>} routes
 * @returns
 */
export const getNoLayoutRoutes = function (routes: IRoute[]) {
  const noLayoutRoutes: IRoute[] = [];
  routes.forEach((route) => {
    const _route = { ...route };
    if (_route.children) {
      _route.children = getNoLayoutRoutes(_route.children) as MenuRoute[];
    }
    if (_route.layoutRender === false || (_route.children || []).length) {
      noLayoutRoutes.push(_route);
    }
  });
  return noLayoutRoutes;
};

/**
 * 将路由配置转化为react-router-dom用配置
 * @param {Array<IRoute>} routes
 * @returns
 */
export const generateRoutes = function (
  routes: (IRoute & { agentShow?: boolean })[],
) {
  return routes.map((route) => {
    let routeItem: RouteObject = {
      path: route.path,
    };
    if (route.component) {
      routeItem.element = route.auth ? (
        <RouteAuth agentShow={route.agentShow}>{route.component}</RouteAuth>
      ) : (
        <>{route.component}</>
      );
    }
    if (route.children) {
      routeItem.children = generateRoutes(route.children);
    }
    return routeItem;
  });
};
