const customerStateMapping = [
  '未知',
  '待跟进',
  '资质不符合',
  '待签约',
  '已签约',
  '已上门',
  '审核中',
  '已放款',
  '在审件',
  '捣乱申请',
  '黑名单',
];

export { customerStateMapping };
