import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default function DatetimePicker({
  value,
  onChange,
  disabled,
}: {
  value?: RangePickerProps['value'];
  onChange?: (value: { startedAt?: string; endedAt?: string }) => void;
  disabled?: boolean;
}) {
  return (
    <RangePicker
      disabled={disabled}
      value={value}
      style={{ width: '100%' }}
      showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      presets={[
        {
          label: '今天',
          value: [dayjs().startOf('day'), dayjs().endOf('day')],
        },
        {
          label: '本周',
          value: [dayjs().startOf('week'), dayjs().endOf('week')],
        },
        {
          label: '本月',
          value: [dayjs().startOf('month'), dayjs().endOf('month')],
        },
        {
          label: '上月',
          value: [
            dayjs().add(-1, 'M').startOf('month'),
            dayjs().add(-1, 'M').endOf('month'),
          ],
        },
      ]}
      onChange={(values) => {
        onChange?.({
          startedAt: values?.[0]?.format(),
          endedAt: values?.[1]?.format(),
        });
      }}
    />
  );
}
