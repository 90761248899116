import { listChannel, listChannelStatis } from '@/apis/api';
import { PageHeader } from '@ant-design/pro-components';
import { useAntdTable } from 'ahooks';
import { Params } from 'ahooks/lib/useAntdTable/types';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import {
  formatSearchFilterAllValue,
  formatTime,
  formateChannelMode,
} from '@/utils';
import { LinkOutlined, ApiOutlined } from '@ant-design/icons';
import AddChannel from './components/AddChannel';
import UpdateChannel from './components/UpdateChannel';
import { createContext, useEffect, useMemo } from 'react';
import { SourceSelect } from '@/components/SourceSelect';
import useUser from '@/hooks/user';
import dayjs from 'dayjs';
import ChannelSelect from '@/components/ChannelSelect';

const context = createContext({ refresh: () => {} });

const CopyAPI = ({
  source,
  channel,
}: {
  source: I.Source;
  channel: string;
}) => {
  return (
    <Tooltip title="复制对接 API">
      <ApiOutlined
        onClick={() => {
          navigator.clipboard.writeText(`BASE_URL https://rmsapi.dev
source_id ${source.id}
TOKEN ${source.token}
channel ${channel}
加密接口 Key ${source.token.substring(0, 8)}

区分大小写
同一个 channel + mobile 一天只能推一次`);
        }}
      />
    </Tooltip>
  );
};

const CopyLink = ({
  source,
  channel,
}: {
  source: I.Source;
  channel: string;
}) => {
  const link = useMemo(() => {
    const qs = new URLSearchParams({
      token: source.token,
      ref: 'rms',
      name: source.name,
      channel,
    });
    return `/push/${source.id}?${qs.toString()}`;
  }, [source, channel]);
  return (
    <Button
      icon={<LinkOutlined />}
      size="small"
      type="link"
      href={link}
      target="_blank"
    />
  );
};

const columns: (ColumnType<I.Channel & { statistic?: I.ChannelStatistic }> & {
  hideInAgent?: boolean;
})[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: '渠道名称',
    dataIndex: 'name',
    render: (name, { source }) => {
      return (
        <Space>
          <span>{name}</span>
          <CopyLink source={source} channel={name} />
          <CopyAPI source={source} channel={name} />
        </Space>
      );
    },
  },
  {
    title: '所属媒体',
    dataIndex: 'source',
    hideInAgent: true,
    render: (source: I.Source) => {
      return (
        <span>
          {source.name}（{source.uniq_name}）
        </span>
      );
    },
  },
  {
    title: '统计信息',
    dataIndex: 'statistic',
    render: (statistic?: I.ChannelStatistic) => {
      const calculatePercent = (num?: number) => {
        const per = (num || 0) / (statistic?.total || 0);
        return (
          <Space>
            <span>
              {num || 0}/{statistic?.total}
            </span>
            <span>({((per || 0) * 100).toFixed(2)}%)</span>
          </Space>
        );
      };
      return (
        <Space direction="vertical">
          <Space>
            <span>0 星</span>
            <span>{calculatePercent(statistic?.star_0)}</span>
          </Space>
          <Space>
            <span>1 星</span>
            <span>{calculatePercent(statistic?.star_1)}</span>
          </Space>
          <Space>
            <span>2 星</span>
            <span>{calculatePercent(statistic?.star_2)}</span>
          </Space>
          <Space>
            <span>3 星</span>
            <span>{calculatePercent(statistic?.star_3)}</span>
          </Space>
          <Space>
            <span>4 星</span>
            <span>{calculatePercent(statistic?.star_4)}</span>
          </Space>
          <Space>
            <span>5 星</span>
            <span>{calculatePercent(statistic?.star_5)}</span>
          </Space>
          <Space>
            <span>优质率</span>
            <span>{calculatePercent(statistic?.star)}</span>
          </Space>
        </Space>
      );
    },
  },
  {
    title: '创建人',
    dataIndex: 'user',
    render: (user: I.User) => {
      return user.name;
    },
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return formatTime(created_at);
    },
  },
  {
    title: '',
    render: (_, record) => {
      return (
        <context.Consumer>
          {({ refresh }) => (
            <UpdateChannel
              channel={record}
              onSuccess={() => {
                refresh();
              }}
            />
          )}
        </context.Consumer>
      );
    },
  },
];

export default function ChannelIndex() {
  const { user } = useUser();
  const [form] = Form.useForm();

  const { tableProps, loading, search, refresh } = useAntdTable<
    Awaited<ReturnType<typeof listChannel>>,
    [Params[0], Params[1]]
  >(
    (page, formData) => {
      return listChannel({
        page: page.current,
        size: page.pageSize,
        ...formData,
        hide: formData.hide === 1 ? true : false,
        mode: formateChannelMode(formData.mode),
        company_id: formatSearchFilterAllValue(formData.company_id),
        source_id: formatSearchFilterAllValue(formData.source_id),
      }).then((res) => {
        const start = formData.start ? formData.start.unix() : undefined;
        const end = formData.end
          ? formData.end.unix()
          : dayjs().endOf('d').unix();
        console.log(formData.start.toISOString());
        return listChannelStatis(start, end).then((statis) => {
          return {
            ...res,
            list: res.list.map((channel) => {
              return {
                ...channel,
                statistic: statis?.find(
                  (item) => item.channel_id === channel.id,
                ),
              };
            }),
          };
        });
      });
    },
    {
      form,
      defaultParams: [
        { current: 1, pageSize: 10 },
        {
          start: dayjs().startOf('d'),
          end: dayjs().endOf('d'),
        },
      ],
    },
  );

  const selectedSourceId = Form.useWatch<I.ID | undefined>('source_id', form);

  useEffect(() => {
    form.resetFields(['channel_id']);
  }, [selectedSourceId, form]);

  const userColumns = useMemo(() => {
    return columns.filter((column) => {
      return user.role === 3 ? !column.hideInAgent : true;
    });
  }, [user.role]);

  const { submit, reset } = search;

  return (
    <PageHeader
      title={'渠道管理'}
      extra={
        user.role === 9
          ? [<AddChannel onSuccess={refresh} key="add" />]
          : undefined
      }
    >
      <Form
        form={form}
        initialValues={{
          company_id: '',
          source_id: '',
          mode: '',
          hide: 0,
        }}
      >
        <Row gutter={24}>
          {user.role !== 3 && (
            <>
              <Col span={8}>
                <Form.Item label="所属媒体" name="source_id">
                  <SourceSelect showAll />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="渠道名称" name="channel_id">
                  <ChannelSelect
                    allowClear
                    sourceId={selectedSourceId}
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={8}>
            <Form.Item label="开始时间" name="start">
              <DatePicker
                format={'YYYY-MM-DD HH:mm:ss'}
                defaultValue={dayjs().startOf('d')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="结束时间" name="end">
              <DatePicker
                format={'YYYY-MM-DD  HH:mm:ss'}
                defaultValue={dayjs().endOf('d')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="隐藏" name="hide">
              <Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} justify="end" style={{ marginBottom: 24 }}>
          <Space>
            <Button type="primary" onClick={submit}>
              搜索
            </Button>
            <Button onClick={reset}>重置</Button>
          </Space>
        </Row>
      </Form>
      <context.Provider value={{ refresh }}>
        <Table
          columns={userColumns}
          {...tableProps}
          loading={loading}
          rowKey={'id'}
        />
      </context.Provider>
    </PageHeader>
  );
}
