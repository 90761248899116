import { listSource } from '@/apis/api';
import { PageHeader } from '@ant-design/pro-components';
import { useAntdTable } from 'ahooks';
import { Params } from 'ahooks/lib/useAntdTable/types';
import { Button, Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import AddSource from './components/AddSource';
import BoolStatus from '@/components/BoolStatus';
import { formatTime } from '@/utils';

const columns: ColumnsType<I.Source> = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: '媒体名称',
    dataIndex: 'name',
    render: (name, { status }) => {
      return (
        <Space>
          <span>{name}</span>
          <span>
            <BoolStatus status={status} />
          </span>
        </Space>
      );
    },
  },
  {
    title: '媒体别名',
    dataIndex: 'uniq_name',
  },
  {
    title: '创建人',
    dataIndex: 'user',
    render: (user: I.User) => {
      return user.name;
    },
  },
  {
    title: '渠道数量',
    dataIndex: 'channels',
    render: (channels?: I.Channel[]) => {
      return <>{channels?.length}</>;
    },
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    render: (created_at: string) => {
      return formatTime(created_at);
    },
  },
];

export default function Source() {
  const [form] = Form.useForm();
  const { tableProps, loading, search, refresh } = useAntdTable<
    Awaited<ReturnType<typeof listSource>>,
    [Params[0], Params[1]]
  >(
    (page, formData) => {
      return listSource({
        page: page.current,
        size: page.pageSize,
        ...formData,
        status: formData.status === '' ? undefined : formData.status,
      });
    },
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, {}],
    },
  );

  const { submit, reset } = search;

  return (
    <PageHeader title={'媒体管理'} extra={[<AddSource onSuccess={refresh} />]}>
      <Form form={form}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="媒体名称" name="name">
              <Input placeholder="媒体名称" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="媒体别名" name="uniq_name">
              <Input placeholder="媒体别名" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="状态" name="status">
              <Select defaultValue={''}>
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={true}>启用</Select.Option>
                <Select.Option value={false}>禁用</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} justify="end" style={{ marginBottom: 24 }}>
          <Space>
            <Button type="primary" onClick={submit}>
              搜索
            </Button>
            <Button onClick={reset}>重置</Button>
          </Space>
        </Row>
      </Form>
      <Table
        columns={columns}
        {...tableProps}
        loading={loading}
        rowKey={'id'}
      />
    </PageHeader>
  );
}
