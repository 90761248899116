import { Select, SelectProps } from 'antd';
import { CustomerFromEnum } from '../api';

const FromSelect = ({
  value,
  onChange,
  ...props
}: {
  value?: CustomerFromEnum;
  onChange: (v: CustomerFromEnum | undefined) => void;
} & SelectProps) => {
  return (
    <Select
      allowClear
      style={{ width: '300px', ...props.style }}
      {...props}
      value={value}
      onSelect={(v) => {
        onChange(v);
      }}
      onClear={() => onChange(undefined)}
      placeholder="客户来源，默认 RMS"
    >
      <Select.Option value={CustomerFromEnum.RMS}>RMS</Select.Option>
      <Select.Option value={CustomerFromEnum.CRM}>CRM</Select.Option>
      <Select.Option value={CustomerFromEnum.OUTSIDER}>
        外部数据源
      </Select.Option>
    </Select>
  );
};

export default FromSelect;
