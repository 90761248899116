import { CustomerFromEnum, NewDataAnalyzeDTO, UserStateEnum } from '@/api';
import { crmProxy } from '@/apis/api';
import DatetimePicker from '@/components/DatetimePicker';
import DepartmentSelect from '@/components/DepartmentSelect';
import FromSelect from '@/components/FromSelect';
import useCompany from '@/hooks/company';
import {
  findAllDepartmentId,
  findChildrenDepartment,
  flatDepartment,
  formatEndTime,
  formatStartTime,
  sortName,
} from '@/utils';
import { useRequest } from 'ahooks';
import { Col, Row, Segmented, Space, Statistic, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

type Type = 'person' | 'department' | 'group' | 'company';

type Data = { id: I.ID; name: string } & Omit<
  NewDataAnalyzeDTO,
  'userName' | 'departmentId' | 'userId' | 'userState'
>;

type CompanyNewDataAnalyzeDTO = NewDataAnalyzeDTO & {
  companyId: I.ID;
  companyName: string;
};

const flatData = (
  validData: CompanyNewDataAnalyzeDTO[],
  id: I.ID,
  name: string,
) => {
  const value = validData.reduce<Omit<Data, 'id' | 'name'>>(
    (prev, cur) => {
      return {
        id: '',
        name: '',
        cnt: Number(prev.cnt) + Number(cur.cnt),
        star0: Number(prev.star0) + Number(cur.star0),
        star1: Number(prev.star1) + Number(cur.star1),
        star2: Number(prev.star2) + Number(cur.star2),
        star3: Number(prev.star3) + Number(cur.star3),
        star4: Number(prev.star4) + Number(cur.star4),
        star5: Number(prev.star5) + Number(cur.star5),
        stateSignedCount:
          Number(prev.stateSignedCount) + Number(cur.stateSignedCount),
        stateVisitedCount:
          Number(prev.stateVisitedCount) + Number(cur.stateVisitedCount),
        withAgentCount:
          Number(prev.withAgentCount) + Number(cur.withAgentCount),
        withAgentSignedCount:
          Number(prev.withAgentSignedCount) + Number(cur.withAgentSignedCount),
        withBrokerage: Number(prev.withBrokerage) + Number(cur.withBrokerage),
        withLoanCount: Number(prev.withLoanCount) + Number(cur.withLoanCount),
        withLoanAmount:
          Number(prev.withLoanAmount) + Number(cur.withLoanAmount),
      };
    },
    {
      cnt: 0,
      star0: 0,
      star1: 0,
      star2: 0,
      star3: 0,
      star4: 0,
      star5: 0,
      stateSignedCount: 0,
      stateVisitedCount: 0,
      withLoanAmount: 0,
      withAgentCount: 0,
      withAgentSignedCount: 0,
      withBrokerage: 0,
      withLoanCount: 0,
    },
  );
  return {
    ...value,
    name,
    id,
  };
};

const columns: ColumnType<Data>[] = [
  {
    dataIndex: 'name',
    align: 'left',
    sorter: (a, b) => sortName(a.name, b.name),
    width: 156,
  },
  {
    dataIndex: 'cnt',
    title: '客户总数',
    sorter: (a, b) => (a.cnt > b.cnt ? 1 : -1),
    width: 96,
  },
  {
    dataIndex: 'stateVisitedCount',
    sorter: (a, b) => (a.stateVisitedCount > b.stateVisitedCount ? 1 : -1),
    render: (_, dto) => {
      const percentage = !!dto.cnt
        ? ((Number(dto.stateVisitedCount) * 100.0) / Number(dto.cnt)).toFixed(2)
        : 0;
      return (
        <span>
          {dto.stateVisitedCount}（
          <Statistic
            value={percentage}
            suffix="%"
            style={{ display: 'inline-block' }}
            valueStyle={{ fontSize: '14px' }}
          />
          ）
        </span>
      );
    },
    title: '总上门量',
    width: 100,
  },
  {
    dataIndex: 'stateSignedCount',
    sorter: (a, b) => (a.stateSignedCount > b.stateSignedCount ? 1 : -1),
    title: '签约量',
    width: 100,
    render: (_, dto) => {
      const percentage = !!dto.stateVisitedCount
        ? (
            (Number(dto.stateSignedCount) * 100.0) /
            Number(dto.stateVisitedCount)
          ).toFixed(2)
        : 0;
      return (
        <span>
          {dto.stateSignedCount}（
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            style={{ display: 'inline-block' }}
            value={percentage}
            suffix="%"
          />
          ）
        </span>
      );
    },
  },
  {
    dataIndex: 'withAgentCount',
    title: '拓展上门量',
    sorter: (a, b) => (a.withAgentCount > b.withAgentCount ? 1 : -1),
    width: 110,
  },
  {
    dataIndex: 'withAgentSignedCount',
    title: '拓展成功量',
    sorter: (a, b) =>
      a.withAgentSignedCount > b.withAgentSignedCount ? 1 : -1,
    width: 110,
  },
  {
    dataIndex: 'withLoanCount',
    title: '放款成功',
    width: 105,
    sorter: (a, b) => (a.withLoanCount > b.withLoanCount ? 1 : -1),
    render: (_, dto) => {
      return (
        <span>
          {dto.withLoanCount}（
          <Statistic
            style={{ display: 'inline-block' }}
            valueStyle={{ fontSize: '14px' }}
            value={Number(dto.withLoanAmount) / 100}
            prefix="¥"
          />
          ）
        </span>
      );
    },
  },
  {
    dataIndex: 'withBrokerage',
    title: '创收',
    width: 65,
    render: (v) => {
      return (
        <Statistic
          value={v / 100}
          prefix="¥"
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
    sorter: (a, b) =>
      Number(a.withBrokerage || 0) > Number(b.withBrokerage || 0) ? 1 : -1,
  },
  {
    title: '平均点位',
    width: 95,
    sorter: (a, b) => {
      const percentageA = !!a.withLoanAmount
        ? (Number(a.withBrokerage) * 100.0) / Number(a.withLoanAmount)
        : 0;
      const percentageB = !!b.withLoanAmount
        ? (Number(b.withBrokerage) * 100.0) / Number(b.withLoanAmount)
        : 0;
      return percentageA > percentageB ? 1 : -1;
    },
    render: (_, dto) => {
      const percentage = !!dto.withLoanAmount
        ? (
            (Number(dto.withBrokerage) * 100.0) /
            Number(dto.withLoanAmount)
          ).toFixed(2)
        : 0;
      return (
        <Statistic
          valueStyle={{ fontSize: '14px' }}
          suffix="%"
          value={percentage}
        />
      );
    },
  },
  ...['5', '4', '3', '2', '1', '0'].map((item) => ({
    title: `${item} 星`,
    dataIndex: `star${item}`,
    width: 65,
    sorter: (a: any, b: any) =>
      a[`star${item}Count`] > b[`star${item}Count`] ? 1 : -1,
  })),
  {
    title: '优质率',
    width: 80,
    sorter: (a, b) => {
      const cal = (item: Data) => {
        const denominator =
          Number(item.star0) +
          Number(item.star1) +
          Number(item.star2) +
          Number(item.star3) +
          Number(item.star4) +
          Number(item.star5);
        const molecule =
          Number(item.star5) + Number(item.star4) + Number(item.star3);
        const percentage = !!denominator ? (molecule * 100.0) / denominator : 0;
        return percentage;
      };
      return cal(a) > cal(b) ? 1 : -1;
    },
    render: (_, item) => {
      const denominator =
        Number(item.star0) +
        Number(item.star1) +
        Number(item.star2) +
        Number(item.star3) +
        Number(item.star4) +
        Number(item.star5);
      const molecule =
        Number(item.star5) + Number(item.star4) + Number(item.star3);
      const percentage = !!denominator
        ? ((molecule * 100.0) / denominator).toFixed(2)
        : 0;
      return (
        <Statistic
          value={percentage}
          suffix="%"
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
  },
];

export default function NewDataAnalyse() {
  const { loading: companyLoading, companies } = useCompany();

  const [companyId, setCompanyId] = useState<I.ID | null>(null);
  const [departmentId, setDepartmentId] = useState<I.ID | null>(null);
  const [companyAndDepartment, setCompanyAndDepartment] = useState<
    [I.ID, I.ID] | null
  >(null);

  const [type, setType] = useState<Type>('department');

  const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().startOf('month'),
    dayjs().endOf('days'),
  ];

  const [createdAt, setCreatedAt] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);

  const [fromList, setFromList] = useState<CustomerFromEnum>();

  const { data: rawData, loading: requestLoading } = useRequest(
    () => {
      return Promise.all(
        (companies || [])
          .filter((c) => {
            if (!companyId) {
              return true;
            }
            return c.id === companyId;
          })
          .map((c) => {
            return crmProxy('GET', c.id, 'new_data_analyze', {
              params: {
                start: formatStartTime(createdAt[0], true),
                end: formatEndTime(createdAt[1], true),
                from: fromList,
              },
            }).then((result) => {
              return (result as NewDataAnalyzeDTO[]).map(
                (item) =>
                  ({
                    ...item,
                    companyId: c.id,
                    companyName: c.name,
                  } as CompanyNewDataAnalyzeDTO),
              );
            });
          }),
      ).then((result) => {
        return result.flat();
      });
    },
    {
      refreshDeps: [companyId, createdAt, fromList, companies],
    },
  );

  const depIds = useMemo(() => {
    return (companyAndDepartment?.length || 0) > 0
      ? companies
          ?.filter((c) => c.id === companyAndDepartment?.[0])
          .map((c) =>
            findAllDepartmentId(
              c.info.departments!,
              companyAndDepartment?.[1],
            ).map((dep) => `${c.id}-${dep.id}`),
          )
          .flat()
      : companies
          ?.map((c) =>
            findAllDepartmentId(c.info.departments!, null).map(
              (dep) => `${c.id}-${dep.id}`,
            ),
          )
          .flat();
  }, [companyAndDepartment, companies]);

  const data = useMemo<Data[]>(() => {
    if (!rawData) {
      return [];
    }

    const validData = rawData.filter(({ userState, departmentId, companyId }) =>
      depIds?.includes(`${companyId}-${departmentId}`),
    );

    switch (type) {
      case 'company': {
        // 按照公司排序
        return (
          companies?.map((item) => {
            const value = validData.filter((i) => i.companyId === item.id);
            return flatData(value, item.id, item.name);
          }) || []
        );
      }
      case 'department': {
        // 按照部门就要先按照公司排序
        const allDirectChildrent =
          (companyAndDepartment?.length || 0) > 0
            ? companies
                ?.filter((c) => c.id === companyAndDepartment?.[0])
                .map((c) => {
                  return c.info.departments
                    .filter((dep) => {
                      if (companyAndDepartment?.[1] === 0) {
                        // null dep, only company
                        // return all directDepartment
                        return !dep.parentId;
                      }
                      return dep.parentId === companyAndDepartment?.[1];
                    })
                    .map((dep) => ({
                      ...dep,
                      companyId: c.id,
                      companyName: c.name,
                      company: c,
                      name: `${c.name}-${dep.name}`,
                      id: `${c.id}-${dep.id}`,
                      depId: dep.id,
                    }));
                })
                .flat()
            : companies
                ?.map((c) => {
                  return c.info.departments
                    .filter((dep) => !dep.parentId)
                    .map((dep) => ({
                      ...dep,
                      companyId: c.id,
                      companyName: c.name,
                      company: c,
                      name: `${c.name}-${dep.name}`,
                      id: `${c.id}-${dep.id}`,
                      depId: dep.id,
                    }));
                })
                .flat();

        return (
          allDirectChildrent
            ?.sort((a, b) => sortName(a.name, b.name))
            .map((item) => {
              const name = `${flatDepartment(
                item.company.info.departments,
                item.id,
              )
                .filter((v) => v.id !== item.id)
                .map((item) => item.name)
                .reverse()
                .join('/')} ${item.name}`;

              const children = findAllDepartmentId(
                item.company.info.departments,
                item.depId,
              ).map((dep) => `${item.companyId}-${dep.id}`);

              const value = validData.filter((item) =>
                children.includes(`${item.companyId}-${item.departmentId}`),
              );
              return flatData(value, item.id, name);
            }) || []
        );
      }
      case 'group': {
        // 找到所有的一级小组
        const selectedDepartments =
          (companyAndDepartment?.length || 0) > 0
            ? companies
                ?.filter((c) => c.id === companyAndDepartment?.[0])
                .map((c) =>
                  findAllDepartmentId(
                    c.info.departments,
                    companyAndDepartment?.[1],
                  ).map((dep) => ({ company: c, dep })),
                )
                .flat()
            : companies
                ?.map((c) =>
                  findAllDepartmentId(c.info.departments, null).map((dep) => ({
                    company: c,
                    dep,
                  })),
                )
                .flat();
        const allDeps = selectedDepartments
          ?.filter(
            (item) =>
              findChildrenDepartment(item.company.info.departments, item.dep.id)
                .length === 0,
          )
          .map((item) => {
            return {
              ...item,
              id: `${item.company.id}-${item.dep.id}`,
            };
          });

        return (
          allDeps?.map((item) => {
            const name = `${item.company.name} ${flatDepartment(
              item.company.info.departments,
              item.dep.id,
            )
              .filter((v) => v.id !== item.id)
              .map((item) => item.name)
              .reverse()
              .join('/')}`;
            const value = validData.filter((i) => {
              return (
                i.departmentId === item.dep.id &&
                i.companyId === item.company.id
              );
            });
            return flatData(value, item.id, name);
          }) || []
        );
      }
    }

    return validData
      .filter((user) => user.userState === UserStateEnum.ACTIVE)
      ?.map((item) => ({
        ...item,
        id: `${item.companyId}-${item.userId}`,
        name: `${item.companyName}-${item.userName}`,
      }));
  }, [rawData, type, companyAndDepartment, companies, depIds]);

  const loading = companyLoading || requestLoading;
  return (
    <Row>
      <Col span={24}>
        <Space>
          <FromSelect
            value={fromList}
            onChange={(v) => setFromList(v)}
            disabled={loading}
          />
          <Segmented
            options={[
              {
                label: '个人',
                value: 'person',
              },
              {
                label: '小组',
                value: 'group',
              },
              {
                label: '区域',
                value: 'department',
              },
              {
                label: '公司',
                value: 'company',
              },
            ]}
            value={type}
            onChange={(v) => setType(v as Type)}
            disabled={loading}
          />

          <DepartmentSelect
            disabled={loading}
            value={
              companyId !== null && departmentId !== null
                ? `${companyId}-${departmentId}`
                : undefined
            }
            onClear={() => {
              setCompanyId(null);
              setDepartmentId(null);
              setCompanyAndDepartment(null);
            }}
            onSelect={(v) => {
              const [companyId, departmentId] = v
                .split('-')
                .map((vv: string) => parseInt(vv));
              setCompanyAndDepartment([companyId, departmentId]);
              setCompanyId(companyId);
              setDepartmentId(departmentId);
            }}
          />
          <DatetimePicker
            disabled={loading}
            value={createdAt}
            onChange={(v) => {
              const start = v.startedAt
                ? dayjs(v.startedAt)
                : defaultCreatedAt[0];
              const end = v.endedAt ? dayjs(v.endedAt) : defaultCreatedAt[1];
              setCreatedAt([start, end]);
            }}
          />
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '24px' }}>
        <Table
          summary={(row) => {
            return (
              <Table.Summary>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>总计</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    {row.reduce((prev, cur) => prev + Number(cur.cnt), 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="right">
                    {/* 总上门量 */}
                    {row.reduce(
                      (prev, cur) => prev + Number(cur.stateVisitedCount),
                      0,
                    )}
                    （
                    <Statistic
                      style={{ display: 'inline-block' }}
                      value={
                        (row.reduce((prev, dto) => {
                          return prev + Number(dto.stateVisitedCount || 0);
                        }, 0) /
                          row.reduce(
                            (prev, dto) => prev + Number(dto.cnt || 0),
                            0,
                          )) *
                        100.0
                      }
                      precision={2}
                      valueStyle={{
                        fontSize: '12px',
                      }}
                      suffix="%"
                    />
                    ）
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="right">
                    {/* 签约量 */}
                    {row.reduce(
                      (prev, cur) => prev + Number(cur.stateSignedCount),
                      0,
                    )}
                    （
                    <Statistic
                      style={{ display: 'inline-block' }}
                      value={
                        (row.reduce((prev, dto) => {
                          return prev + Number(dto.stateSignedCount || 0);
                        }, 0) /
                          row.reduce(
                            (prev, dto) =>
                              prev + Number(dto.stateVisitedCount || 0),
                            0,
                          )) *
                          100.0 || 0
                      }
                      precision={2}
                      valueStyle={{
                        fontSize: '12px',
                      }}
                      suffix="%"
                    />
                    ）
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="right">
                    {row.reduce(
                      (prev, cur) => prev + Number(cur.withAgentCount),
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="right">
                    {row.reduce(
                      (prev, cur) => prev + Number(cur.withAgentSignedCount),
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="right">
                    {/* 放款成功 */}
                    <span>
                      {row.reduce(
                        (prev, cur) => prev + Number(cur.withLoanCount),
                        0,
                      )}
                      （
                      <Statistic
                        style={{ display: 'inline-block' }}
                        value={
                          row.reduce(
                            (prev, cur) => prev + Number(cur.withLoanAmount),
                            0,
                          ) / 100
                        }
                        prefix="¥"
                        valueStyle={{ fontSize: '14px' }}
                        precision={2}
                      />
                      ）
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="right">
                    {/* 创收 */}
                    <Statistic
                      value={
                        row.reduce(
                          (prev, cur) => prev + Number(cur.withBrokerage),
                          0,
                        ) / 100
                      }
                      prefix="¥"
                      valueStyle={{ fontSize: '14px' }}
                      precision={2}
                    />
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="right">
                    {/* 平均点位 */}
                    {(
                      (row.reduce(
                        (prev, cur) => prev + Number(cur.withBrokerage),
                        0,
                      ) /
                        row.reduce(
                          (prev, cur) => prev + Number(cur.withLoanAmount),
                          0,
                        )) *
                        100 || 0
                    ).toFixed(2)}
                    %
                  </Table.Summary.Cell>
                  {[5, 4, 3, 2, 1, 0].map((star) => (
                    <Table.Summary.Cell
                      index={star + 8}
                      key={star}
                      align="right"
                    >
                      {row.reduce(
                        (prev, cur: any) => prev + Number(cur[`star${star}`]),
                        0,
                      )}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={15} align="right">
                    {(
                      (row.reduce((prev, item) => {
                        const molecule =
                          Number(item.star5) +
                          Number(item.star4) +
                          Number(item.star3);
                        return prev + molecule;
                      }, 0) /
                        row.reduce((prev, item) => {
                          return (
                            prev +
                            Number(item.star0) +
                            Number(item.star1) +
                            Number(item.star2) +
                            Number(item.star3) +
                            Number(item.star4) +
                            Number(item.star5)
                          );
                        }, 0)) *
                      100.0
                    ).toFixed(2)}
                    %
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          loading={loading}
          rowKey={'id'}
          dataSource={data}
          columns={columns.map((item) => {
            const align = item.align || 'right';
            return {
              ...item,
              align,
            };
          })}
          size="small"
          bordered
          pagination={false}
        />
      </Col>
    </Row>
  );
}
