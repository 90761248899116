import useUser from '@/hooks/user';
import { Watermark } from 'antd';
import { PropsWithChildren } from 'react';

export default function WatermarkWrapper({ children }: PropsWithChildren) {
  const { user } = useUser();
  return (
    <Watermark
      content={
        user && [
          user?.name,
          user?.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2'),
        ]
      }
      // font={{ fontSize: '12px' }}
      zIndex={9999}
      gap={[50, 50]}
    >
      {children}
    </Watermark>
  );
}
