import Source from '@/pages/source/Index';
import { IRoute } from './router.helper';
import ChannelIndex from '@/pages/channel';
import CompanyIndex from '@/pages/company';
import CustomerIndex from '@/pages/customer';
import UpdatePassword from '@/pages/user/UpdatePassword';
import ChannelPush from '@/pages/channelpush';
import ChannelAnalyse from '@/pages/report/ChannelAnalyse';
import NewDataAnalyse from '@/pages/report/NewDataAnalyse';
import HandleAnalyse from '@/pages/report/HandleAnalyse';

const routes: (IRoute & { agentShow?: boolean })[] = [
  {
    path: '/',
    name: '首页',
    key: '/',
    auth: true,
    component: <>hello</>,
  },
  {
    path: '/source',
    name: '媒体管理',
    auth: true,
    key: '/source',
    component: <Source />,
  },
  {
    path: '/channel',
    auth: true,
    name: '渠道管理',
    agentShow: true,
    key: '/channel',
    component: <ChannelIndex />,
  },
  {
    path: '/channel_push',
    auth: true,
    name: '推送管理',
    key: '/channel_push',
    component: <ChannelPush />,
  },
  {
    path: '/company',
    auth: true,
    name: '公司管理',
    key: '/company',
    component: <CompanyIndex />,
  },
  {
    path: '/customer',
    auth: true,
    name: '资源管理',
    key: '/customer',
    component: <CustomerIndex />,
  },
  {
    path: '/user/updatePassword',
    auth: true,
    name: '修改密码',
    key: '/user/updatePassword',
    component: <UpdatePassword />,
  },
  {
    path: '/report/channel',
    auth: true,
    name: '渠道报表',
    key: '/report/channel',
    component: <ChannelAnalyse />,
  },
  {
    path: '/report/new_data',
    auth: true,
    name: '数据跟进',
    key: '/report/new_data',
    component: <NewDataAnalyse />,
  },
  {
    path: '/report/handle',
    auth: true,
    name: '工作跟进',
    key: '/report/handle',
    component: <HandleAnalyse />,
  },
];

export default routes;
