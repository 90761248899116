import { addSource } from '@/apis/api';
import { useRequest } from 'ahooks';
import { Button, Form, Input, Modal, message } from 'antd';
import { useState } from 'react';

export default function AddSource({ onSuccess }: { onSuccess: () => void }) {
  const [open, setOpen] = useState<boolean>(false);

  const [form] = Form.useForm<{ name: string; uniq_name: string }>();

  const { loading, run } = useRequest(
    (params: { name: string; uniq_name: string }) => {
      return addSource(params);
    },
    {
      manual: true,
      onSuccess: () => {
        setOpen(false);
        message.success('添加成功');
        onSuccess();
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return run(values);
      })
      .catch((e) => {});
  };

  return (
    <>
      <Modal
        title={'添加媒体'}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onSubmit}
        cancelText={'取消'}
        okText={'确定'}
        confirmLoading={loading}
      >
        <Form form={form} preserve={false}>
          <Form.Item
            label={'媒体名称'}
            name={'name'}
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label={'媒体别名'}
            name={'uniq_name'}
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        添加
      </Button>
    </>
  );
}
