import useCompany from '@/hooks/company';
import { flatDepartment, sortName } from '@/utils';
import { TreeSelect, TreeSelectProps } from 'antd';
import { useMemo } from 'react';

interface TreeData {
  key: I.ID;
  title: string;
  value: I.ID;
  children?: TreeData[];
}

export const buildTree = (
  companyName: string,
  list: I.Department[],
  rootID: I.ID | null,
  prefixId: I.ID,
): TreeData[] | undefined => {
  if (!list?.length) {
    return undefined;
  }
  const root = list.filter(({ parentId }) => parentId === rootID);

  return root
    .sort((a, b) => sortName(a.name, b.name))
    .map((item) => {
      const name = `${companyName}-${flatDepartment(list, item.id)
        .filter((v) => v.id !== item.id)
        .map((item) => item.name)
        .reverse()
        .join('/')} ${item.name}`;
      return {
        key: `${prefixId}-${item.id}`,
        fullName: name,
        title: item.name,
        value: `${prefixId}-${item.id}`,
        children: buildTree(companyName, list, item.id, prefixId),
      };
    });
};

const DepartmentSelect = ({ ...props }: TreeSelectProps) => {
  const { loading, companies } = useCompany();

  const data = useMemo(() => {
    return companies?.map(({ info, ...c }) => {
      return {
        key: `${c.id}-0`,
        title: c.name,
        value: `${c.id}-0`,
        children: buildTree(c.name, info.departments, null, c.id),
      };
    });
  }, [companies]);
  return (
    <TreeSelect
      {...props}
      style={{ width: '300px', ...props.style }}
      showSearch
      allowClear
      disabled={!data?.length || props.disabled}
      // treeDefaultExpandAll
      placeholder={loading ? '加载中...' : '全部部门'}
      treeData={data}
      loading={loading}
    />
  );
};

export default DepartmentSelect;
