import { updatePassword } from '@/apis/api';
import useUser from '@/hooks/user';
import { PageHeader } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { Button, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function UpdatePassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { user } = useUser();

  const { loading, run } = useRequest(
    (data: { password: string; password2: string; old_password: string }) => {
      return updatePassword(user.id, data);
    },
    {
      manual: true,
      refreshDeps: [user.id],
      onSuccess: () => {
        message.success('修改成功，请重新登录');
        navigate('/login');
      },
      onError: (e) => {
        message.warning(e.message);
      },
    },
  );
  const onFinish = (values: {
    password: string;
    password2: string;
    old_password: string;
  }) => {
    return run(values);
  };
  return (
    <PageHeader title="修改密码">
      <Form
        onFinish={onFinish}
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        autoComplete="off"
      >
        <Form.Item
          label="旧的密码"
          name="old_password"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="新的密码"
          name="password"
          rules={[
            { required: true },
            { min: 8 },
            {
              validator: (_, value) => {
                const oldPassword = form.getFieldValue('old_password');
                if (oldPassword && oldPassword === value) {
                  return Promise.reject('新旧密码不能一样');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="password2"
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                const password = form.getFieldValue('password');
                if (password && password !== value) {
                  return Promise.reject('两次密码不一致');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            修改
          </Button>
        </Form.Item>
      </Form>
    </PageHeader>
  );
}
