import { addChannel } from '@/apis/api';
import { useRequest } from 'ahooks';
import { Button, Form, Input, Modal, Radio, message } from 'antd';
import { useState } from 'react';
import { SourceSelect } from '@/components/SourceSelect';

interface Props {
  onSuccess: () => void;
  submit: (data: I.Channel) => Promise<unknown>;
  initData?: I.Channel;
  title: string;
  buttonText: string;
  disabledField?: string[];
}

export default function ChannelFormModalEditor({
  onSuccess,
  initData,
  title,
  submit,
  buttonText,
  disabledField = [],
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const [form] = Form.useForm<Parameters<typeof addChannel>[0]>();

  const { loading, run } = useRequest(submit, {
    manual: true,
    onSuccess: () => {
      setOpen(false);
      message.success('添加成功');
      onSuccess();
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return run(values);
      })
      .catch(() => {});
  };

  return (
    <>
      <Modal
        title={title}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onSubmit}
        cancelText={'取消'}
        okText={'确定'}
        confirmLoading={loading}
      >
        <Form
          form={form}
          preserve={false}
          initialValues={{
            mode: 'mean',
            status: false,
            ...initData,
          }}
        >
          <Form.Item
            label={'渠道名称'}
            name={'name'}
            rules={[{ required: true }]}
          >
            <Input
              autoComplete="off"
              disabled={disabledField.includes('name')}
            />
          </Form.Item>
          <Form.Item
            label="所属媒体"
            name="source_id"
            rules={[{ required: true }]}
          >
            <SourceSelect disabled={disabledField.includes('source_id')} />
          </Form.Item>
          <Form.Item label="隐藏" name="hide" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        {buttonText}
      </Button>
    </>
  );
}
