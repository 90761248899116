import { listAllCompany, listCompanyInfo } from '@/apis/api';
import { useRequest } from 'ahooks';
import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

const context = createContext<{
  loading?: boolean;
  companies?: (I.Company & { info: I.CompanyInfo })[];
}>({});

export const CompanyProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();

  const { data, loading } = useRequest(
    () => {
      return listAllCompany().then((company) => {
        return Promise.all(
          company.map((c) => {
            return listCompanyInfo(c.id).then((info) => ({
              ...c,
              info,
            }));
          }),
        );
      });
    },
    {
      refreshDeps: [location],
    },
  );

  return (
    <context.Provider value={{ loading, companies: data }}>
      {children}
    </context.Provider>
  );
};

export default function useCompany() {
  return useContext(context);
}
