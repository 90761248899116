import { HandleAnalyseDTO } from '@/api';
import DatetimePicker from '@/components/DatetimePicker';
import DepartmentSelect from '@/components/DepartmentSelect';
import { Col, Row, Segmented, Space } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

type Data = HandleAnalyseDTO & { name?: string; ix: number };

type Type = 'person' | 'department' | 'group';

export default function HandleAnalyse() {
  const [companyAndDepartment, setCompanyAndDepartment] = useState<
    [I.ID, I.ID] | null
  >(null);

  const [type, setType] = useState<Type>('department');

  const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().startOf('month'),
    dayjs(),
  ];

  const [dateRange, setDateRange] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);

  const loading = false;
  return (
    <Row>
      <Col span={24}>
        <Space>
          <Segmented
            options={[
              {
                label: '个人',
                value: 'person',
              },
              {
                label: '小组',
                value: 'group',
              },
              {
                label: '区域',
                value: 'department',
              },
            ]}
            value={type}
            onChange={(v) => setType(v as Type)}
            disabled={loading}
          />
          <DepartmentSelect
            value={companyAndDepartment?.[1]}
            onChange={(v) => {}}
            style={{ width: '300px' }}
            disabled={loading}
          />
          <DatetimePicker
            disabled={loading}
            value={dateRange}
            onChange={(v) => {
              const start = v.startedAt
                ? dayjs(v.startedAt)
                : defaultCreatedAt[0];
              const end = v.endedAt ? dayjs(v.endedAt) : defaultCreatedAt[1];
              setDateRange([start, end]);
            }}
          />
        </Space>
      </Col>
    </Row>
  );
}
