import {
  APPLICATION_JSON,
  CONTENT_TYPE,
  InvokeParams,
  request,
} from '@huolala-tech/request';
import { RequestFailed } from './RequestFailed';

export interface Options extends Omit<InvokeParams, 'method' | 'url' | 'data'> {
  data?: unknown;
  params?: Record<string, any>;
  prefix?: string;
}

const removeUndefiendValues = (params: unknown) => {
  if (!params) return {};
  if (params instanceof URLSearchParams) {
    return params;
  }
  return JSON.parse(JSON.stringify(params));
};

export abstract class BaseApi {
  protected abstract defaultPrefix: string;

  public static async request<T = unknown>(
    options: InvokeParams & { params?: Record<string, any> },
  ) {
    const { url, params, headers = {}, ...rest } = options;
    const qs = new URLSearchParams(removeUndefiendValues(params))
      .toString()
      // Prepends '?' character if not empty.
      .replace(/^./, '?$&');
    const res = await request<T>({
      url: `${url}${qs}`,
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        ...headers,
      },
      ...rest,
    });
    const { statusCode } = res;
    if (statusCode >= 200 && statusCode < 300) {
      return res.data;
    }
    throw new RequestFailed(res);
  }

  public async request<T = unknown>(
    method: string,
    path: string,
    options: Options = {},
  ) {
    const { prefix = this.defaultPrefix, data, ...rest } = options;
    const url = `${prefix}${path}`;
    return BaseApi.request<T>({
      method,
      url,
      data: data as any,
      ...rest,
    });
  }

  public get<T>(url: string, options?: Options) {
    return this.request<T>('GET', url, options);
  }

  public post<T>(url: string, options?: Options) {
    return this.request<T>('POST', url, options);
  }

  public patch<T>(url: string, options?: Options) {
    return this.request<T>('PATCH', url, options);
  }

  public delete<T>(url: string, options?: Options) {
    return this.request<T>('DELETE', url, options);
  }

  public put<T>(url: string, options?: Options) {
    return this.request<T>('PUT', url, options);
  }
}

export default class RMSApi extends BaseApi {
  protected defaultPrefix =
    process.env.NODE_ENV === 'development'
      ? 'https://rms-dev-api.home.hui.lu'
      : '/api';
}

export const getMobileHashURL = () => {
  return `${
    process.env.NODE_ENV === 'development'
      ? 'https://rms-dev-api.home.hui.lu'
      : '/api'
  }/system/download_mobile_hash_fast`;
};

export const getAnalysisURL = (prev?: boolean) => {
  const query = prev ? '?prev=1' : '';
  return `${
    process.env.NODE_ENV === 'development'
      ? 'https://rms-dev-api.home.hui.lu'
      : '/api'
  }/system/export_monthly_data${query}`;
};
