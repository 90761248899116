import { listAllSource } from '@/apis/api';
import { useRequest } from 'ahooks';
import { Select, SelectProps } from 'antd';

export function SourceSelect({
  showAll,
  ...props
}: SelectProps<I.ID> & { showAll?: boolean }) {
  const { data, loading } = useRequest(() => {
    return listAllSource();
  });
  return (
    <Select {...props} loading={loading}>
      {showAll && <Select.Option value={''}>全部</Select.Option>}
      {data?.map((source) => (
        <Select.Option value={source.id} key={source.id}>
          {source.name}（{source.uniq_name}）
        </Select.Option>
      ))}
    </Select>
  );
}
